import React from "react";
import PropTypes from "prop-types";

const IconSocialDribbble = ({ color, size }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 32 32">
      <path
        fill={color}
        d="M15.6 13.8c-1.1-2-2.4-3.7-2.5-4-1.9.9-3.3 2.7-3.8 4.8.3 0 3.1 0 6.3-.8zM16.5 16.1c.1 0 .2-.1.3-.1l-.6-1.2c-3.4 1-6.7 1-7 1v.2c0 1.8.7 3.4 1.8 4.6.1-.3 2-3.4 5.5-4.5zM20.5 10.9c-1.2-1.1-2.8-1.7-4.5-1.7-.6 0-1.1.1-1.6.2.2.3 1.4 1.9 2.5 4 2.5-.9 3.5-2.3 3.6-2.5zM17.2 17.1c-3.9 1.3-5.2 4-5.4 4.3 1.2.9 2.6 1.4 4.2 1.4.9 0 1.8-.2 2.7-.5-.1-.6-.5-2.7-1.5-5.2z"
      />
      <path
        fill={color}
        d="M16 0C7.2 0 0 7.2 0 16s7.2 16 16 16 16-7.2 16-16S24.8 0 16 0zm0 24c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8z"
      />
      <path
        fill={color}
        d="M17.5 14.4c.2.3.3.7.5 1 .1.1.1.2.1.4 2.3-.3 4.5.2 4.8.2 0-1.6-.6-3.1-1.5-4.3-.3.2-1.4 1.7-3.9 2.7zM18.5 16.8c.9 2.5 1.3 4.5 1.3 4.9 1.5-1 2.6-2.7 2.9-4.6-.2-.1-2.1-.6-4.2-.3z"
      />
    </svg>
  );
};
IconSocialDribbble.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};
IconSocialDribbble.defaultProps = { color: "black", size: 32 };

export default IconSocialDribbble;
