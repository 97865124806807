import React from "react";
import PropTypes from "prop-types";

const IconSocialWhatsapp = ({ color, size }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 32 32">
      <path
        fill={color}
        d="M16 9.3c-3.6 0-6.6 3-6.6 6.6 0 1.2.3 2.5 1 3.5l.2.2-.7 2.4 2.5-.7.2.1c1 .6 2.2.9 3.4.9 3.6 0 6.6-3 6.6-6.6 0-1.8-.7-3.4-1.9-4.7-1.2-1-2.9-1.7-4.7-1.7zm3.9 9.5c-.2.5-1 .9-1.3.9-.3.1-.8.1-1.2-.1-.3-.1-.7-.2-1.1-.4-2-.9-3.3-2.9-3.4-3-.1-.1-.8-1.1-.8-2 0-1 .5-1.5.7-1.7.2-.2.4-.2.5-.2h.4c.1 0 .3 0 .4.3.2.4.6 1.4.6 1.5s.1.2 0 .3c-.1.1-.1.2-.2.3-.1.1-.2.3-.3.3-.1.1-.2.2-.1.4s.5.8 1.1 1.4c.8.7 1.4.9 1.6 1 .2.1.3.1.4 0 .1-.1.5-.6.6-.8.1-.2.3-.2.4-.1.2.1 1.2.5 1.4.6.2.1.3.1.4.2.1.2.1.6-.1 1.1z"
      />
      <path
        fill={color}
        d="M16 0C7.2 0 0 7.2 0 16s7.2 16 16 16 16-7.2 16-16S24.8 0 16 0zm0 23.9c-1.3 0-2.6-.3-3.8-1L8 24l1.1-4.1c-.7-1.2-1.1-2.6-1.1-4 .1-4.3 3.7-7.9 8-7.9 2.1 0 4.1.8 5.6 2.3 1.5 1.5 2.3 3.5 2.3 5.6.1 4.4-3.5 8-7.9 8z"
      />
    </svg>
  );
};
IconSocialWhatsapp.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};
IconSocialWhatsapp.defaultProps = { color: "black", size: 32 };

export default IconSocialWhatsapp;
