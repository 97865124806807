import React from "react";
import PropTypes from "prop-types";

const IconMessage = ({ color, size, strokeWidth }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24">
      <path
        d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5h0z"
        stroke={color}
        strokeWidth={strokeWidth}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
IconMessage.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  strokeWidth: PropTypes.number,
};
IconMessage.defaultProps = { color: "black", size: 24, strokeWidth: 2 };

export default IconMessage;
