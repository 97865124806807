import React, { cloneElement } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import theme from "../constants/theme";

/**
 * IconFrame
 */
const IconFrame = ({
  icon,
  iconSize,
  iconColor,
  iconStrokeWidth,
  frameColor,
}) => {
  const baseStyle = classNames("inline-block p-16 rounded-full");
  return (
    <div className={baseStyle} style={{ backgroundColor: frameColor }}>
      {cloneElement(icon, {
        size: iconSize,
        color: iconColor,
        strokeWidth: iconStrokeWidth,
      })}
    </div>
  );
};
IconFrame.propTypes = {
  icon: PropTypes.element,
  iconSize: PropTypes.number,
  iconColor: PropTypes.string,
  iconStrokeWidth: PropTypes.string,
  frameColor: PropTypes.string,
};
IconFrame.defaultProps = {
  iconSize: 24,
  iconColor: "white",
  iconStrokeWidth: 2,
  frameColor: "black",
};

export default IconFrame;
