// Set useCdn to `false` if you want to ensure fresh data

import * as sanityClientLib from "@sanity/client";

const sanityClient = sanityClientLib({
  projectId: process.env.SANITY_PROJECT_ID,
  dataset: process.env.SANITY_DATASET,
  token: process.env.SANITY_TOKEN,
  useCdn: true,
});

export default sanityClient;
