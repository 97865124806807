import React from "react";
import BaseBlockContent from "@sanity/block-content-to-react";
import cx from "classnames";

import * as sanityUtils from "../utils/sanityUtils";

const _paragraph = children => (
  <div className="flex flex-col items-center">
    <p
      className={cx(
        "text-md3 text-text3 leading-normal w-10/12 lg:w-6/12 text-center",
        "mt-20 mb-20"
      )}>
      {children}
    </p>
  </div>
);

const _image = (imageUrl, isShowBorder) => {
  const borderStyle = isShowBorder ? "shadow-outline" : "shadow-none";
  return (
    <div className={cx(borderStyle)}>
      <img className="w-full" src={sanityUtils.urlFor(imageUrl)} />
    </div>
  );
};

const _annotation = text => {
  return text ? <p className="text-sm text-text3 py-6">{text}</p> : null;
};

const serializers = {
  types: {
    block(props) {
      switch (props.node.style) {
        case "h2":
          return (
            <h2 className="font-medium text-md tracking-tight_sm mt-40 mb-20 text-center text-accent1">
              {props.children}
            </h2>
          );
        case "normal":
          return _paragraph(props.children);
        default:
          return _paragraph(props.children);
      }
    },
    singleImage(props) {
      return (
        <div className={cx("mt-30 mb-30")}>
          <div className="flex flex-col">
            {_image(props.node.image.asset, props.node.isShowBorder)}
            {_annotation(props.node.annotation)}
          </div>
        </div>
      );
    },
    doubleImage(props) {
      return (
        <div className={cx("mt-30 mb-30")}>
          <div className="flex flex-col">
            <div className={cx("grid grid-cols-1 md:grid-cols-2 gap-20")}>
              {_image(props.node.image1.asset, props.node.isImage1ShowBorder)}
              {_image(props.node.image2.asset, props.node.isImage2ShowBorder)}
            </div>
            {_annotation(props.node.annotation)}
          </div>
        </div>
      );
    },
  },
};

const imageOptions = {};

/**
 * BlockContent
 */
const BlockContent = ({ blocks }) => (
  <BaseBlockContent
    projectId={process.env.SANITY_PROJECT_ID}
    dataset={process.env.SANITY_DATASET}
    blocks={blocks}
    serializers={serializers}
    imageOptions={imageOptions}
  />
);

export default BlockContent;
