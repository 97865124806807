import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Link } from "gatsby";

/**
 * Button
 */
const Button = props => {
  const {
    mode,
    to,
    href,
    onClick,
    label,
    icon,
    iconPosition,
    size,
    roundness,
    labelColor,
    frameColor,
    hoverFrameColor,
    isStretch,
  } = props;

  const _buttonSize = {
    sm: 40,
    md: 48,
    lg: 56,
  };
  const _buttonSidePadding = {
    sm: 10,
    md: 12,
    lg: 20,
  };
  const _buttonShape = {
    none: 0,
    classic: 8,
    round: 9999,
  };
  const _labelSize = {
    sm: 16,
    md: 18,
    lg: 18,
  };
  const _buttonWidth = isStretch ? "100%" : "auto";

  const _icon = () => {
    const _iconClass =
      iconPosition === "right"
        ? "ml-6 order-last"
        : iconPosition === "left"
        ? "mr-6 order-first"
        : "ml-6 order-last";

    const _iconClass2 = {
      right: "ml-6 order-last",
      left: "mr-6 order-first",
    };

    return icon ? (
      <div className={_iconClass2[iconPosition]}>{icon}</div>
    ) : null;
  };

  const GatsbyLink = props => {
    const {
      children,
      to,
      size,
      frameColor,
      hoverFrameColor,
      roundness,
    } = props;
    return (
      <Link
        to={to}
        style={{
          width: _buttonWidth,
          height: _buttonSize[size],
          borderRadius: _buttonShape[roundness],
          paddingLeft: _buttonSidePadding[size],
          paddingRight: _buttonSidePadding[size],
        }}
        className={cx("inline-block", frameColor, `hover:${hoverFrameColor}`)}>
        {children}
      </Link>
    );
  };

  const HrefLink = props => {
    const {
      children,
      href,
      size,
      frameColor,
      hoverFrameColor,
      roundness,
    } = props;
    return (
      <a
        href={href}
        style={{
          width: _buttonWidth,
          height: _buttonSize[size],
          borderRadius: _buttonShape[roundness],
          paddingLeft: _buttonSidePadding[size],
          paddingRight: _buttonSidePadding[size],
        }}
        className={cx("inline-block", frameColor, `hover:${hoverFrameColor}`)}
        target="_blank"
        rel="noopener norefferer">
        {children}
      </a>
    );
  };

  const Button = props => {
    const {
      children,
      onClick,
      size,
      frameColor,
      hoverFrameColor,
      roundness,
    } = props;
    return (
      <button
        onClick={onClick}
        style={{
          width: _buttonWidth,
          height: _buttonSize[size],
          backgroundColor: frameColor,
          borderRadius: _buttonShape[roundness],
          paddingLeft: _buttonSidePadding[size],
          paddingRight: _buttonSidePadding[size],
          outline: "none",
        }}
        className={cx("inline-block", frameColor, `hover:${hoverFrameColor}`)}>
        {children}
      </button>
    );
  };

  const Contents = props => {
    const { labelColor } = props;
    return (
      <div className="flex justify-center items-center h-full">
        {_icon()}
        <div
          style={{
            fontSize: _labelSize[size],
            letterSpacing: "-0.01em",
            textAlign: "center",
            verticalAlign: "middle",
          }}
          className={cx(labelColor)}>
          {label}
        </div>
      </div>
    );
  };

  const _component =
    mode === "route" ? (
      <GatsbyLink {...props}>
        <Contents {...props} />
      </GatsbyLink>
    ) : mode === "href" ? (
      <HrefLink {...props}>
        <Contents {...props} />
      </HrefLink>
    ) : mode === "button" ? (
      <Button {...props}>
        <Contents {...props} />
      </Button>
    ) : (
      <GatsbyLink {...props}>
        <Contents {...props} />
      </GatsbyLink>
    );

  return <>{_component}</>;
};
Button.propTypes = {
  to: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
  mode: PropTypes.oneOf(["route", "href", "button"]),
  label: PropTypes.string,
  icon: PropTypes.element,
  iconPosition: PropTypes.oneOf(["left", "right"]),
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  roundness: PropTypes.oneOf(["none", "classic", "full"]),
  labelColor: PropTypes.string,
  frameColor: PropTypes.string,
  hoverFrameColor: PropTypes.string,
  isStretch: PropTypes.bool,
};
Button.defaultProps = {
  mode: "route",
  label: "Button",
  size: "md",
  iconPosition: "right",
  roundness: "classic",
  labelColor: "white",
  frameColor: "black",
  hoverFrameColor: "blue",
  isStretch: false,
};

export default Button;
