import React from "react";
import PropTypes from "prop-types";

const IconSocialPayPal = ({ color, size }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 32 32">
      <path
        fill={color}
        d="M16 0C7.2 0 0 7.2 0 16s7.2 16 16 16 16-7.2 16-16S24.8 0 16 0zM9.6 22.2c-.3 0-.5-.2-.4-.5l2.1-13.1c.1-.3.3-.6.7-.6h5c1.7 0 3.1.4 3.8 1.2.7.8.9 1.6.7 2.9 0 0 0 .2-.1.3-.7 3.4-2.9 4.5-5.8 4.5h-1.5c-.4 0-.6.3-.7.6v-.2l-.8 4.9h-3zm13.1-6.6c-.6 2.9-2.5 4-5 4h-.4c-.3 0-.6.2-.6.5v.2l-.5 3.1v.1c0 .3-.3.5-.6.5h-2.1l1-6.2h1.3c3.6 0 5.9-1.8 6.6-5.1.4.7.5 1.7.3 2.9z"
      />
    </svg>
  );
};
IconSocialPayPal.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};
IconSocialPayPal.defaultProps = { color: "black", size: 32 };

export default IconSocialPayPal;
