import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import cx from "classnames";

import theme from "../constants/theme";

import { Wrapper } from "./";
import { logoPuppetbrain } from "../assets/graphics";
import {
  IconEmail,
  IconPhone,
  IconHome,
  IconDatabase,
} from "../assets/@feathericons";
import {
  IconSocialDribbble,
  IconSocialTwitter,
  IconSocialInstagram,
  IconSocialLinkedIn,
} from "../assets/@socialicons";

/**
 * Footer
 */
const Footer = ({ siteProps }) => {
  return (
    <footer className="bg-colorBg2">
      <div className="flex flex-col md:flex-row px-30">
        <div className="flex-1 py-20 md:py-40">
          <div className="flex flex-col items-center md:items-start">
            <Link to="/">
              <div className="flex items-center">
                <div className="w-48">
                  <img
                    className="w-full h-full"
                    src={logoPuppetbrain}
                    alt={siteProps.company}
                  />
                </div>
                <div className="bg-transparent w-10" />
                <p className="text-sm2 text-accent1 tracking-wide_lg uppercase">
                  {siteProps.slogan}
                </p>
              </div>
            </Link>
          </div>
        </div>

        <div className="flex-1 py-20 md:py-40">
          <div className="flex flex-col items-center md:items-start">
            <div className="flex items-center mb-6">
              <IconEmail color={theme.colors.accent1} />
              <a className="hover:underline" href={`mailto:${siteProps.email}`}>
                <p className="text-text1 text-sm ml-6">{siteProps.email}</p>
              </a>
            </div>

            <div className="flex items-center mb-6">
              <IconHome color={theme.colors.accent1} />
              <a className="hover:underline" href={siteProps.mapsUrl}>
                <p className="text-text1 text-sm ml-6">{siteProps.location}</p>
              </a>
            </div>

            <div className="flex items-center">
              <IconDatabase color={theme.colors.accent1} />
              <p className="text-text1 text-sm ml-6">
                {siteProps.chamberOfCommerce}
              </p>
            </div>
          </div>
        </div>

        <div className="flex-1 pt-40 pb-20 md:pb-40">
          <nav className="flex justify-center md:justify-end">
            <a href={siteProps.twitterUrl} className="w-40 h-40">
              <IconSocialTwitter color={theme.colors.accent1} />
            </a>
            <a href={siteProps.dribbbleUrl} className="w-40 h-40">
              <IconSocialDribbble color={theme.colors.accent1} />
            </a>
            <a href={siteProps.instagramUrl} className="w-40 h-40">
              <IconSocialInstagram color={theme.colors.accent1} />
            </a>
            <a href={siteProps.linkedInUrl} className="w-40 h-40">
              <IconSocialLinkedIn color={theme.colors.accent1} />
            </a>
          </nav>
        </div>
      </div>

      <div className="flex-1 items-center px-30 py-40">
        <p className="text-text3 text-sm text-center lg:text-left">
          {`Copyright © ${
            siteProps.companyStartYear
          } - ${new Date().getFullYear()} ${siteProps.company}`}
        </p>
      </div>
    </footer>
  );
};
Footer.propTypes = {};
Footer.defaultProps = {};

export default Footer;
