import React from "react";
import PropTypes from "prop-types";

const IconArrowRight = ({ color, size, strokeWidth }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24">
      <g
        stroke={color}
        strokeWidth={strokeWidth}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round">
        <path d="M5 12h14M12 5l7 7-7 7" />
      </g>
    </svg>
  );
};
IconArrowRight.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  strokeWidth: PropTypes.number,
};
IconArrowRight.defaultProps = { color: "black", size: 24, strokeWidth: 2 };

export default IconArrowRight;
