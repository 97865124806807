import React from "react";
import PropTypes from "prop-types";

const IconSocialInstagram = ({ color, size }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 32 32">
      <circle fill={color} cx={16} cy={16} r={3.1} />
      <path
        fill={color}
        d="M19.6 12.9h2.2c.2 0 .4-.2.4-.4v-2.2c0-.2-.2-.4-.4-.4h-2.2c-.2 0-.4.2-.4.4v2.2c-.1.2.1.4.4.4z"
      />
      <path
        fill={color}
        d="M16 0C7.2 0 0 7.2 0 16s7.2 16 16 16 16-7.2 16-16S24.8 0 16 0zm8 22.1c0 1-.9 1.9-1.9 1.9H9.9c-1 0-1.9-.8-1.9-1.9V9.9C8 8.8 8.9 8 9.9 8h12.2c1 0 1.9.8 1.9 1.9v12.2z"
      />
      <path
        fill={color}
        d="M20.9 16c0 2.7-2.2 4.9-4.9 4.9s-4.9-2.2-4.9-4.9c0-.4.1-.9.2-1.3H9.8v7c0 .2.2.4.4.4h11.5c.2 0 .4-.2.4-.4v-7h-1.5c.2.4.3.9.3 1.3z"
      />
    </svg>
  );
};
IconSocialInstagram.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};
IconSocialInstagram.defaultProps = { color: "black", size: 32 };

export default IconSocialInstagram;
