import React from "react";
import PropTypes from "prop-types";

const IconZoomOut = ({ color, size, strokeWidth }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round">
      <circle cx={11} cy={11} r={8} />
      <path d="M21 21l-4.35-4.35M8 11h6" />
    </svg>
  );
};
IconZoomOut.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  strokeWidth: PropTypes.number,
};
IconZoomOut.defaultProps = { color: "black", size: 24, strokeWidth: 2 };

export default IconZoomOut;
