import React from "react";
import PropTypes from "prop-types";

const IconDisc = ({ color, size, strokeWidth }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24">
      <g
        transform="translate(2 2)"
        stroke={color}
        strokeWidth={strokeWidth}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round">
        <circle cx={10} cy={10} r={10} />
        <circle cx={10} cy={10} r={3} />
      </g>
    </svg>
  );
};
IconDisc.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  strokeWidth: PropTypes.number,
};
IconDisc.defaultProps = { color: "black", size: 24, strokeWidth: 2 };

export default IconDisc;
