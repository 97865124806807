import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { navigate, Link } from "gatsby";
import cx from "classnames";

import theme from "../constants/theme";
import MenuContext from "../context/MenuContext";

import { Button, IconButton } from "./";
import { IconCross } from "../assets/@feathericons";
import { logoPuppetbrain } from "../assets/graphics";

/**
 * MobileNav
 */
const MobileNav = ({ siteProps }) => {
  const _linkStyle = cx(
    "text-md3 text-altText1_60 tracking-tight_xs text-left",
    "px-20 py-16",
    "bg-transparent",
    "hover:bg-accent1_25"
  );

  const _iconClose = <IconCross />;

  const _navigateTo = route => {
    navigate(route);
  };

  const _mobileNavBar = () => {
    return (
      <div className="flex h-100">
        <div className="flex w-80 md:flex-1 lg:hidden justify-start items-center pl-20 md:pl-30">
          <MenuContext.Consumer>
            {menuContext => {
              return (
                <IconButton
                  onClick={() => menuContext.toggleMenu()}
                  icon={_iconClose}
                  iconColor={theme.colors.bg1}
                  frameColor={theme.colors.accent1}
                />
              );
            }}
          </MenuContext.Consumer>
        </div>

        <div
          className={cx(
            "flex flex-1 justify-center items-center lg:items-end h-full"
          )}>
          <Link to="/">
            <div className="flex flex-col items-center">
              <div className="w-48">
                <img
                  className="w-full h-full"
                  src={logoPuppetbrain}
                  alt={siteProps.company}
                />
              </div>
              <div className="bg-transparent h-10" />
              <p className="text-sm2 text-altText1 tracking-wide_lg uppercase text-center">
                {siteProps.slogan}
              </p>
            </div>
          </Link>
        </div>

        <div
          className={cx(
            "flex w-80 md:flex-1 justify-end items-center h-full",
            "pr-30"
          )}>
          <div className="hidden md:block">
            <Button
              mode="href"
              href={`mailto:${siteProps.email}`}
              label="Request a quote"
              labelColor={"text-accent1"}
              frameColor={"bg-bg1"}
              hoverFrameColor={"bg-bg1_80"}
            />
          </div>
        </div>
      </div>
    );
  };

  const _mainNav = menuContext => {
    return (
      <div className="flex flex-col">
        <h3 className="font-medium text-accent1 text-sm2 uppercase tracking-wide_md px-20 py-8">
          Menu
        </h3>
        <MenuContext.Consumer>
          {menuContext => {
            return (
              <nav className="flex flex-col">
                <button
                  onClick={() => {
                    menuContext.toggleMenu();
                    _navigateTo("/");
                  }}
                  className={_linkStyle}>
                  Work
                </button>
                <button
                  onClick={() => {
                    menuContext.toggleMenu();
                    _navigateTo("/about");
                  }}
                  className={_linkStyle}>
                  About
                </button>
                <button
                  onClick={() => {
                    menuContext.toggleMenu();
                    _navigateTo("/blog");
                  }}
                  className={_linkStyle}>
                  Blog
                </button>
                <button
                  onClick={() => {
                    menuContext.toggleMenu();
                    _navigateTo("/contact");
                  }}
                  className={_linkStyle}>
                  Contact
                </button>
              </nav>
            );
          }}
        </MenuContext.Consumer>
      </div>
    );
  };

  const _form = () => {
    return (
      <div className="flex flex-col p-20">
        <Button
          mode="href"
          href={`mailto:${siteProps.email}`}
          label="Request a quote"
          labelColor={"text-altText1"}
          frameColor={"bg-accent1"}
          hoverFrameColor={"bg-accent1_90"}
          size="lg"
        />
      </div>
    );
  };

  return (
    <MenuContext.Consumer>
      {menuContext => {
        const menuBaseStyle =
          "fixed lg:hidden flex-col justify-start w-full h-screen bg-altBg1";
        const menuStyle = menuContext.isMenuOpen
          ? cx(menuBaseStyle, "flex")
          : cx(menuBaseStyle, "hidden");

        return (
          <div style={{ zIndex: 10000 }} className={menuStyle}>
            {_mobileNavBar()}
            <div className="bg-transparent h-20"></div>
            {_mainNav(menuContext)}
            <div className="bg-transparent h-20"></div>
            {_form()}
          </div>
        );
      }}
    </MenuContext.Consumer>
  );
};
MobileNav.propTypes = {};
MobileNav.defaultProps = {};

export default MobileNav;
