module.exports = {
  fontSize: {
    lg: `var(--fontSize-lg)`,
    lg2: `var(--fontSize-lg2)`,
    lg3: `var(--fontSize-lg3)`,
    lg4: `var(--fontSize-lg4)`,
    md: `var(--fontSize-md)`,
    md2: `var(--fontSize-md2)`,
    md3: `var(--fontSize-md3)`,
    md4: `var(--fontSize-md4)`,
    sm: `var(--fontSize-sm)`,
    sm2: `var(--fontSize-sm2)`,
    sm3: `var(--fontSize-sm3)`,
    sm4: `var(--fontSize-sm4)`,
  },
  colors: {
    none: "transparent",
    accent1: `var(--color-accent1)`,
    accent1_15: `var(--color-accent1-15)`,
    accent1_25: `var(--color-accent1-25)`,
    accent1_45: `var(--color-accent1-45)`,
    accent1_60: `var(--color-accent1-60)`,
    accent1_80: `var(--color-accent1-80)`,
    accent1_90: `var(--color-accent1-90)`,
    accent2: `var(--color-accent2)`,
    accent2_15: `var(--color-accent2-15)`,
    accent2_25: `var(--color-accent2-25)`,
    accent2_45: `var(--color-accent2-45)`,
    accent2_60: `var(--color-accent2-60)`,
    accent2_80: `var(--color-accent2-80)`,
    accent2_90: `var(--color-accent2-90)`,
    text1: `var(--color-text1)`,
    text1_60: `var(--color-text1-60)`,
    text2: `var(--color-text2)`,
    text3: `var(--color-text3)`,
    altText1: `var(--color-altText1)`,
    altText1_60: `var(--color-altText1-60)`,
    altText2: `var(--color-altText2)`,
    altText3: `var(--color-altText3)`,
    bg1: `var(--color-bg1)`,
    bg1_15: `var(--color-bg1-15)`,
    bg1_25: `var(--color-bg1-25)`,
    bg1_60: `var(--color-bg1-60)`,
    bg1_80: `var(--color-bg1-80)`,
    bg2: `var(--color-bg2)`,
    bg3: `var(--color-bg3)`,
    colorBg1: `var(--color-colorBg1)`,
    colorBg2: `var(--color-colorBg2)`,
    altBg1: `var(--color-altBg1)`,
    altBg1_15: `var(--color-altBg1-15)`,
    altBg1_25: `var(--color-altBg1-25)`,
    altBg1_60: `var(--color-altBg1-60)`,
    altBg2: `var(--color-altBg2)`,
    statusPositive: `var(--color-statusPositive)`,
    statusPositive_10: `var(--color-statusPositive-10)`,
    statusWarning: `var(--color-statusWarning)`,
    statusWarning_10: `var(--color-statusWarning-10)`,
    statusFailure: `var(--color-statusFailure)`,
    statusFailure_10: `var(--color-statusFailure-10)`,
    category1: `var(--color-category1)`,
    category1_25: `var(--color-category1-25)`,
  },
  spacing: {
    sectionY: "py-40 md:py-60 lg:py-80",
    sectionYTight: "py-20 md:py-40",
    sectionYTop: "pt-40 md:pt-60 lg:pt-80",
    sectionYTopExtra: "pt-120 md:pt-160",
    sectionYBottom: "pb-40 md:pb-60 lg:pb-80",
    blockY: "h-40 md:h-60",
    blockYTight: "h-40",
    blockYLoose: "h-40 md:h-60 lg:h-80",
  },
};
