import React from "react";
import PropTypes from "prop-types";

const IconSocialGitHub = ({ color, size }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 32 32">
      <path
        fill={color}
        d="M16 0C7.2 0 0 7.2 0 16s7.2 16 16 16 16-7.2 16-16S24.8 0 16 0zm2.3 22.4s-1.1.1-2.3.1-2.3-.1-2.3-.1C10.9 22.1 8 21 8 16.2c0-1.4.5-2.5 1.3-3.4-.1-.3-.6-1.6.1-3.3 0 0 1.1-.3 3.4 1.3 1-.3 2.1-.4 3.1-.4 1.1 0 2.1.1 3.1.4 2.4-1.6 3.4-1.3 3.4-1.3.7 1.7.3 3 .1 3.3.8.9 1.3 2 1.3 3.4.2 4.8-2.7 5.9-5.5 6.2z"
      />
    </svg>
  );
};
IconSocialGitHub.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};
IconSocialGitHub.defaultProps = { color: "black", size: 32 };

export default IconSocialGitHub;
