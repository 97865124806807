import React from "react";
import PropTypes from "prop-types";

const IconSocialYouTube = ({ color, size }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 32 32">
      <path
        fill={color}
        d="M16 0C7.2 0 0 7.2 0 16s7.2 16 16 16 16-7.2 16-16S24.8 0 16 0zm8 16.6c0 1.3-.2 2.6-.2 2.6s-.2 1.1-.6 1.6c-.6.6-1.3.6-1.6.7-2.2.2-5.6.2-5.6.2s-4.2 0-5.4-.2c-.4-.1-1.2 0-1.8-.7-.5-.5-.6-1.6-.6-1.6S8 17.9 8 16.6v-1.2c0-1.3.2-2.6.2-2.6s.2-1.1.6-1.6c.6-.6 1.3-.6 1.6-.7 2.2-.2 5.6-.2 5.6-.2s3.4 0 5.6.2c.3 0 1 0 1.6.7.5.5.6 1.6.6 1.6s.2 1.3.2 2.6v1.2z"
      />
      <path fill={color} d="M14.3 18.1l4.4-2.3-4.4-2.2z" />
    </svg>
  );
};
IconSocialYouTube.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};
IconSocialYouTube.defaultProps = { color: "black", size: 32 };

export default IconSocialYouTube;
