import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import "./styles/reset.css";
import "./styles/global.css";
import "./styles/tailwind.css";

import { NavBar, Footer, MobileNav } from "./";

/**
 * Layout
 */
const Layout = ({ children }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            company
            slogan
            companyStartYear
            chamberOfCommerce
            email
            phone
            location
            websiteUrl
            mapsUrl
            twitterUrl
            dribbbleUrl
            instagramUrl
            linkedInUrl
          }
        }
      }
    `
  );

  const {
    title,
    company,
    slogan,
    companyStartYear,
    chamberOfCommerce,
    email,
    phone,
    location,
    websiteUrl,
    mapsUrl,
    twitterUrl,
    dribbbleUrl,
    instagramUrl,
    linkedInUrl,
  } = data.site.siteMetadata;

  return (
    <div className="bg-colorBg2">
      <MobileNav siteProps={{ company, slogan, email }} />
      <NavBar
        siteProps={{
          company,
          slogan,
          email,
        }}
      />
      <main>{children}</main>
      <Footer
        siteProps={{
          company,
          slogan,
          companyStartYear,
          chamberOfCommerce,
          email,
          phone,
          location,
          websiteUrl,
          mapsUrl,
          twitterUrl,
          dribbbleUrl,
          instagramUrl,
          linkedInUrl,
        }}
      />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
Layout.defaultProps = {};

export default Layout;
