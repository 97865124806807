import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
/** @jsx jsx */
import { jsx, css } from "@emotion/core";

import { Link } from "gatsby";
import { Button, IconButton } from "./";
import { IconMenu } from "../assets/@feathericons";
import { logoPuppetbrain } from "../assets/graphics";

import theme from "../constants/theme";
import MenuContext from "../context/MenuContext";

const tempCloseButton = () =>
  // <IconButton
  //   onClick={() => menuContext.toggleMenu()}
  //   icon={_iconMenu}
  //   iconColor={theme.colors.bg1}
  //   frameColor={theme.colors.accent1}
  // />
  null;

/**
 * NavBar
 */
const NavBar = ({ siteProps }) => {
  const _linkStyle = {
    fontSize: theme.fontSize.md4,
    color: theme.colors.text3,
    marginRight: 20,
    [`&:hover`]: {
      color: theme.colors.accent1,
    },
  };
  const _activeLinkStyle = {
    fontSize: theme.fontSize.md4,
    color: theme.colors.accent1,
    marginRight: 20,
  };

  const _iconMenu = <IconMenu />;

  return (
    <div className="flex h-100 bg-bg1">
      <div
        className={cx(
          "hidden lg:flex flex-1 justify-start items-center h-full",
          "px-30"
        )}>
        <nav className="flex  hidden">
          <Link css={_linkStyle} activeStyle={_activeLinkStyle} to="/">
            Work
          </Link>
          <Link css={_linkStyle} activeStyle={_activeLinkStyle} to="/about">
            About
          </Link>
          <Link css={_linkStyle} activeStyle={_activeLinkStyle} to="/blog">
            Blog
          </Link>
          <Link css={_linkStyle} activeStyle={_activeLinkStyle} to="/contact">
            Contact
          </Link>
        </nav>
      </div>

      <div className="flex w-80 md:flex-1 lg:hidden justify-start items-center pl-20 md:pl-30">
        <MenuContext.Consumer>
          {menuContext => {
            return <div className="w-44 h-44"></div>;
          }}
        </MenuContext.Consumer>
      </div>

      <div
        className={cx(
          "flex flex-1 justify-center items-center lg:items-end h-full"
        )}>
        <Link to="/">
          <div className="flex flex-col items-center">
            <div className="w-48">
              <img
                className="w-full h-full"
                src={logoPuppetbrain}
                alt={siteProps.company}
              />
            </div>
            <div className="bg-transparent h-10" />
            <p className="text-sm2 text-accent1 tracking-wide_lg uppercase text-center">
              {siteProps.slogan}
            </p>
          </div>
        </Link>
      </div>

      <div
        className={cx(
          "flex w-80 md:flex-1 justify-end items-center h-full",
          "pr-30"
        )}>
        <div className="hidden md:block">
          <Button
            mode="href"
            href={`mailto:${siteProps.email}`}
            label="Request a quote"
            labelColor={"text-altText1"}
            frameColor={"bg-accent1"}
            hoverFrameColor={"bg-accent1_90"}
          />
        </div>
      </div>
    </div>
  );
};
NavBar.propTypes = {};
NavBar.defaultProps = {};

export default NavBar;
