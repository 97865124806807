import React, { cloneElement } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

/**
 * IconButton
 */
const IconButton = ({
  onClick,
  icon,
  iconSize,
  iconColor,
  iconStrokeWidth,
  frameSize,
  frameColor,
}) => {
  const _frameSizes = {
    sm: 36,
    md: 44,
    lg: 52,
  };

  return (
    <div
      onClick={onClick}
      style={{
        width: _frameSizes[frameSize],
        height: _frameSizes[frameSize],
        backgroundColor: frameColor,
        cursor: "pointer",
      }}
      className="flex justify-center items-center rounded-full">
      {cloneElement(icon, {
        size: iconSize,
        color: iconColor,
        strokeWidth: iconStrokeWidth,
      })}
    </div>
  );
};
IconButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.element,
  iconSize: PropTypes.number,
  iconColor: PropTypes.string,
  iconStrokeWidth: PropTypes.string,
  frameSize: PropTypes.oneOf(["sm", "md", "lg"]),
  frameColor: PropTypes.string,
};
IconButton.defaultProps = {
  iconSize: 24,
  iconColor: "white",
  iconStrokeWidth: 2,
  frameSize: "md",
  frameColor: "black",
};

export default IconButton;
