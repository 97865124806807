import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import { Link } from "gatsby";

import theme from "../constants/theme";

const ProjectThumbnailCard = ({
  to,
  title,
  skills,
  imageUrl,
  isShowCoverImageBorder,
}) => {
  const _mapSkills = arr => {
    if (arr.length === 0) return "No skills";
    return arr.map((item, index) => {
      const dot =
        index === arr.length - 1 ? null : (
          <div className="w-4 h-4 rounded-full bg-accent1 mr-4 mt-2"></div>
        );

      return (
        <div key={index} className="flex items-center">
          <p className="text-sm3 text-text3 mr-4">{item.title}</p>
          {dot}
        </div>
      );
    });
  };

  const _borderStyle = isShowCoverImageBorder
    ? "shadow-outline"
    : "shadow-none";

  return (
    <Link to={to}>
      <div className="flex flex-col">
        <div
          className={cx(
            "relative w-full h-full pb-12/12",
            "bg-altBg1",
            _borderStyle
          )}>
          <img
            className={cx(
              "absolute top-0 left-0 object-cover w-full h-full",
              "hover:p-6",
              "transition-all duration-300 ease-out"
            )}
            src={imageUrl}
            alt={title}
          />
        </div>

        <div className="flex items-center w-full h-64">
          <div className="flex flex-col">
            <h2 className="font-medium text-md4 text-text1 tracking-tight_md">
              {title}
            </h2>
            <div className="bg-transparent h-6" />
            <div className="flex">{_mapSkills(skills)}</div>
          </div>
        </div>
      </div>
    </Link>
  );
};
ProjectThumbnailCard.propTypes = {
  to: PropTypes.string,
  title: PropTypes.string,
  skills: PropTypes.array,
  imageUrl: PropTypes.string,
  isShowCoverImageBorder: PropTypes.bool,
};
ProjectThumbnailCard.defaultProps = {
  isShowCoverImageBorder: false,
};

export default ProjectThumbnailCard;
