import React from "react";
import PropTypes from "prop-types";

const IconSocialLinkedIn = ({ color, size }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 32 32">
      <path
        fill={color}
        d="M16 0C7.2 0 0 7.2 0 16s7.2 16 16 16 16-7.2 16-16S24.8 0 16 0zm-4.4 24H8.3V13.3h3.3V24zM9.9 11.9c-1 0-1.9-.9-1.9-2C8 8.9 8.9 8 9.9 8c1.1 0 1.9.9 1.9 1.9 0 1.1-.8 2-1.9 2zM24 24h-3.3v-5.2c0-1.2 0-2.8-1.7-2.8s-2 1.3-2 2.7V24h-3.3V13.3h3.2v1.5c.4-.8 1.5-1.7 3.1-1.7 3.4 0 4 2.2 4 5.1V24z"
      />
    </svg>
  );
};
IconSocialLinkedIn.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};
IconSocialLinkedIn.defaultProps = { color: "black", size: 32 };

export default IconSocialLinkedIn;
